export function process($from: HTMLElement, idPrefix: string) {
  for (const $el of $from.querySelectorAll<HTMLDivElement>(
    ".gk-unified-code"
  )) {
    new GKUnifiedCode($el, idPrefix)
  }
  for (const $el of $from.querySelectorAll<HTMLDivElement>(".gk-code")) {
    processGKCode($el, idPrefix)
  }
}

function processGKCode($el: HTMLDivElement, idPrefix: string, tabber?: Tabber) {
  if ($el.dataset.processed) return
  if (!tabber) {
    tabber = new Tabber()
    new GKCode($el, idPrefix, tabber, false)
    tabber.placeEl((tabber) => $el.before(tabber))
  } else {
    new GKCode($el, idPrefix, tabber, true)
  }
}

class GKUnifiedCode {
  constructor($el: HTMLElement, idPrefix: string) {
    ;({
      tab: this.initTabStyle,
      row: this.initRowStyle,
      col: this.initColStyle,
    })[$el.dataset.gkStyle ?? "tab"]!($el, idPrefix)
  }
  private initTabStyle($el: HTMLElement, idPrefix: string) {
    const tabber = new Tabber()
    for (const $child of $el.querySelectorAll<HTMLDivElement>(".gk-code")) {
      processGKCode($child, idPrefix, tabber)
    }
    tabber.placeEl((tabber) => $el.prepend(tabber))
  }
  private initRowStyle($el: HTMLElement, idPrefix: string) {
    const tabbers = []
    for (const $child of $el.querySelectorAll<HTMLDivElement>(".gk-code")) {
      const tabber = new Tabber()
      processGKCode($child, idPrefix, tabber)
      tabbers.push(tabber)
    }
    for (const tabber of tabbers.reverse()) {
      tabber.placeEl((tabber) => $el.prepend(tabber))
    }
    $el.style.setProperty("--gk-code-count", `${tabbers.length}`)
  }
  private initColStyle($el: HTMLElement, idPrefix: string) {
    for (const $child of $el.querySelectorAll<HTMLDivElement>(".gk-code")) {
      const tabber = new Tabber()
      processGKCode($child, idPrefix, tabber)
      tabber.placeEl(($tabber) => $child.before($tabber))
    }
  }
}

class Env {
  private hue = 0
  nextColor() {
    // use golden angle approximation
    this.hue = (this.hue + 137.508) % 360
    this.hue++
    return `hsla(${this.hue},50%,60%, 1)`
  }
}

const env = new Env()

type VisibilitySM = SM<"visible" | "hidden">

class Tabber {
  private $el?: HTMLElement
  private $elList?: HTMLElement
  private sms = <VisibilitySM[]>[]
  private tabs = <HTMLElement[]>[]
  private $prevButton?: HTMLElement
  private $nextButton?: HTMLElement
  private activeIndex = 0
  private create$ElList(): HTMLElement {
    if (this.$el) return this.$elList!
    this.$el = $("div", {
      class: "gk-tabber",
      children: [
        (this.$elList = $("div", {
          class: "gk-tab-list",
        })),
      ],
    })
    return this.$elList
  }
  private onTabClick(i: number) {
    this.activeIndex = i
    for (let j = 0; j < this.sms.length; j++) {
      this.sms[j].set(i === j ? "visible" : "hidden")
    }
    this.adjustIndicator()
  }
  add(text: string, sm: VisibilitySM) {
    const $el = this.create$ElList()
    const $tab = $("div", { class: "gk-tab", children: text })
    $el.append($tab)
    const i = this.sms.length
    $tab.addEventListener("click", () => this.onTabClick(i))
    this.sms.push(sm)
    this.tabs.push($tab)
    sm.classSetter("gk-active", "visible").bind($tab)
  }
  placeEl(placeFn: (el: HTMLElement) => void) {
    if (!this.$el) return
    this.onTabClick(0)
    this.$prevButton = $("div", {
      class: ["gk-tab-button", "gk-tab-button-prev", "gk-disabled"],
      children: [$("span")],
    })
    this.$prevButton.addEventListener("click", () => {
      this.$elList!.scrollBy({ left: -100, behavior: "smooth" })
      this.adjustButtons(this.$elList!.scrollLeft - 100)
    })

    this.$el.append(this.$prevButton)
    this.$nextButton = $("div", {
      class: ["gk-tab-button", "gk-tab-button-next", "gk-disabled"],
      children: [$("span")],
    })
    this.$nextButton.addEventListener("click", () => {
      this.$elList!.scrollBy({ left: 100, behavior: "smooth" })
      this.adjustButtons(this.$elList!.scrollLeft + 100)
    })
    this.$el.append(this.$nextButton)
    placeFn(this.$el)
    this.adjustButtons(0)
    new ResizeObserver(() => {
      this.adjustButtons(this.$elList!.scrollLeft)
      this.adjustIndicator()
    }).observe(this.$el!)
  }
  private adjustIndicator() {
    let x = 0
    for (let i = 0; i < this.activeIndex; i++) {
      x += this.tabs[i].getBoundingClientRect().width
    }
    const width = this.tabs[this.activeIndex].getBoundingClientRect().width
    this.$elList!.style.setProperty("--gk-tab-indicator-left", `${x}px`)
    this.$elList!.style.setProperty("--gk-tab-indicator-width", `${width}px`)
  }
  private adjustButtons(scrollLeft: number) {
    let totalWidth = 0
    for (const $tab of this.tabs) {
      totalWidth += $tab.getBoundingClientRect().width
    }
    const visualWidth = this.$el!.getBoundingClientRect().width
    this.$prevButton!.classList.toggle("gk-disabled", scrollLeft <= 0)
    this.$nextButton!.classList.toggle(
      "gk-disabled",
      totalWidth - scrollLeft <= visualWidth + 2
    )
  }
}

class GKCode {
  constructor(
    private $el: HTMLElement,
    idPrefix: string,
    tabber: Tabber,
    forceTitle: boolean
  ) {
    $el.id = `${idPrefix}${$el.dataset.gkId!}`
    $el.dataset.processed = "true"
    const $pre = $el.querySelector(".gk-code-display > pre")! as HTMLPreElement

    new GKSection($pre, idPrefix, undefined)

    const sm = SM.binary("visible", "hidden", "visible")
    sm.classSetter("gk-hidden", "hidden").bind($el)

    let titleText = this.$el.dataset.gkTitle
    if (!titleText && forceTitle) {
      titleText = this.$el.dataset.gkId!
    }
    if (titleText) tabber.add(titleText, sm)
  }
}

class GKSection {
  constructor(
    readonly $el: HTMLElement,
    idPrefix: string,
    tooltipContainer: TooltipContainer | undefined
  ) {
    const id = $el.dataset.gkSid
    if (id) {
      $el.id = `${idPrefix}${id}`
    }
    tooltipContainer = this.createTooltip(tooltipContainer)
    for (const $child of $el.children) {
      if (!$child.classList.contains("gk-section")) continue
      new GKSection($child as HTMLElement, idPrefix, tooltipContainer)
    }

    switch ($el.dataset.gkType) {
      case "zip":
        this.createZipper()
        break
    }
  }
  private createTooltip(
    tc: TooltipContainer | undefined
  ): TooltipContainer | undefined {
    const desc = this.$el.dataset.gkDesc
    if (!desc) return tc
    if (!tc) {
      tc = new TooltipContainer(this.$el)
    }

    tc.register({
      for: this,
      content: desc,
      show: !!this.$el.dataset.gkDescShow,
    })
    return tc
  }

  private $firstLine(): HTMLElement {
    return this.$el.querySelector(".line") as HTMLElement
  }

  private createZipper() {
    const sm = SM.binary("visible", "hidden", "visible")
    const $ellip = $("span", { class: "gk-ellipsis", children: "…" })
    const $firstLine = this.$firstLine()
    $firstLine.appendChild($ellip)
    $firstLine.classList.add("gk-zip-first-line")
    $firstLine.addEventListener("click", () => {
      if (window.getSelection()?.isCollapsed) sm.advance()
    })

    const $iconCaret = $("div", {
      class: "gk-gutter-line",
      children: [
        $("span", {
          class: "gk-icon-caret",
        }),
      ],
    })
    $firstLine.appendChild($iconCaret)

    if (this.$el.classList.contains("zipped")) {
      sm.set("hidden")
    }

    sm.classSetter("gk-hidden", "hidden")
      .bind(this.$el)
      .bind($iconCaret)
      .bind($ellip)
  }
}

type TooltipOption = Readonly<{
  for: GKSection
  content: string
  show: boolean
}>

class Tooltip {
  readonly linesCount: number
  readonly color: string
  constructor(
    private readonly container: TooltipContainer,
    public readonly option: TooltipOption,
    private readonly level: number
  ) {
    this.linesCount = option.content.split("\n").length
    this.color = env.nextColor()
    this.layout()
  }
  private layout() {
    const $container = this.container.$el
    const level = this.level

    const $vline1 = $("div", {
      class: "gk-tooltip-vline1",
      style: {
        "--color": this.color,
        "--level": `${level}`,
      },
    })
    $container.appendChild($vline1)

    let body: { html: string } | string
    if (this.option.content.startsWith("html:")) {
      body = { html: this.option.content.slice(5) }
    } else {
      body = this.option.content
    }

    const $box = $("div", {
      class: "gk-tooltip-box",
      style: {
        "--color": this.color,
        "--level": `${level}`,
      },
      children: [
        $("div", {
          class: "gk-tooltip-box-text",
          children: [$("span", { children: body })],
        }),
      ],
    })
    $container.appendChild($box)

    const $hline = $("div", {
      class: "gk-tooltip-hline",
      style: {
        "--color": this.color,
        "--level": `${level}`,
      },
    })
    $box.prepend($hline)

    const $vline2 = $("div", {
      class: "gk-tooltip-vline2",
      style: {
        "--color": this.color,
        "--level": `${level}`,
      },
      children: [$("span")],
    })
    this.option.for.$el.prepend($vline2)

    this.container.switcherClassSetter.bind($vline2).bind($box)
  }
}

class TooltipContainer {
  public readonly $el: HTMLElement
  public readonly tooltips: Tooltip[] = []
  constructor($parent: HTMLElement) {
    this.$el = $("div", { class: "gk-tooltip-container" })
    $parent.after(this.$el)

    let $button: HTMLElement
    const $switcher = $("div", {
      class: "gk-tooltip-switcher",
      children: [
        ($button = $("div", {
          class: "gk-tooltip-switcher-button",
        })),
      ],
    })
    $button.addEventListener("click", () => this.switcherSM.advance())
    this.$el.before($switcher)
    this.switcherClassSetter.bind(this.$el).bind($button)
  }
  register(option: TooltipOption) {
    this.tooltips.push(new Tooltip(this, option, this.tooltips.length))
    if (option.show) this.switcherSM.set("visible")
    this.$el.style.setProperty("--n", `${this.tooltips.length}`)
  }
  readonly switcherSM = SM.binary("visible", "hidden", "hidden")
  readonly switcherClassSetter = this.switcherSM.classSetter(
    "gk-hidden",
    "hidden"
  )
}

type StateChangeHandler<Names> = (n: Names) => void

abstract class Setter<I extends string, V> {
  private readonly elements = <HTMLElement[]>[]
  private lastInput?: I
  constructor(private evalFn: (i: I) => Record<string, V>) {}
  bind($el: HTMLElement): Setter<I, V> {
    this.elements.push($el)
    if (this.lastInput !== undefined) {
      this.fireElement($el, this.evalFn(this.lastInput))
    }
    return this
  }
  protected abstract fireElement(
    $el: HTMLElement,
    style: Record<string, V>
  ): void
  fire(i: I) {
    this.lastInput = i
    const style = this.evalFn(i)
    for (const $el of this.elements) {
      this.fireElement($el, style)
    }
  }
}

class ClassSetter<I extends string> extends Setter<I, boolean> {
  protected fireElement($el: HTMLElement, style: Record<string, boolean>) {
    for (const [k, v] of Object.entries(style)) {
      $el.classList.toggle(k, v)
    }
  }
}

type TransitionMap<Names extends string> = {
  [k in Names]: Names
}

class SM<K extends string> {
  private readonly handlers = <StateChangeHandler<K>[]>[]
  get currentState() {
    return this.state
  }
  constructor(private map: TransitionMap<K>, private state: K) {}
  private fire() {
    for (const h of this.handlers) {
      h(this.state)
    }
  }
  onChanged(h: StateChangeHandler<K>) {
    this.handlers.push(h)
    h(this.state)
    return this
  }

  set(state: K) {
    this.state = state
    this.fire()
  }
  advance() {
    this.set(this.map[this.state])
  }

  classSetter(className: string, state: K): ClassSetter<K> {
    const s = new ClassSetter((i: K) => ({ [className]: i === state }))
    this.onChanged((state) => s.fire(state))
    return s
  }

  static binary<K1 extends string, K2 extends string>(
    k1: K1,
    k2: K2,
    initial: K1 | K2
  ): SM<K1 | K2> {
    return new SM({ [k1]: k2, [k2]: k1 } as TransitionMap<K1 | K2>, initial)
  }
}

function $(
  tagName: string,
  option?: {
    class?: string[] | string
    style?: Record<string, string>
    children?: HTMLElement[] | string | { html: string }
  }
): HTMLElement {
  const $el = document.createElement(tagName)
  if (option?.class) {
    if (typeof option.class === "string") {
      $el.classList.add(option.class)
    } else {
      $el.classList.add(...option.class)
    }
  }
  if (option?.style) {
    for (const [k, v] of Object.entries(option.style)) {
      $el.style.setProperty(k, v)
    }
  }
  if (option?.children) {
    if (typeof option.children === "string") {
      $el.appendChild(document.createTextNode(option.children))
    } else if (Array.isArray(option.children)) {
      for (const c of option.children) {
        $el.appendChild(c)
      }
    } else {
      $el.innerHTML = option.children.html
    }
  }
  return $el
}
