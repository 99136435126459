declare global {
  interface Window {
    msCrypto: Crypto
    ENDEC: {
      decryptEager: ($el: HTMLElement, kind: string) => void
      onCleartext: (fn: ($el: HTMLElement) => any) => void
    }
    MathJax: any
    $fontchan: {
      decodeCss: () => Promise<DataView>
      injectCss: () => Promise<void>
      sha: string
    }
  }

  interface Crypto {
    webkitSubtle: SubtleCrypto
  }
}

import "./cnfonts"
window.$fontchan.injectCss()

import "./gh-button"
import "./mathjax"
import "./sidebar"
import "./weixin"
import "./toc"

window.ENDEC = window.ENDEC || {
  onCleartext(fn: (el: Element) => void) {
    document.querySelectorAll("div.post__content").forEach((el) => fn(el))
  },
}

/* Register listeners on posts being decrypted.
   THE ORDER MUST NOT CHANGE */

// 1. Assign HTML Id to each post
window.ENDEC.onCleartext(($el) => {
  const postId = $el.dataset.postId ?? "id"
  $el.id = `post-${postId}`
})

// 2. Initialize footer
import littlefoot from "littlefoot"

window.ENDEC.onCleartext(($el) => {
  littlefoot({ scope: "#" + $el.id })
})

// 3. Initialize codex blocks generated by genko-markdown
import { process as processBlocks } from "../../../node_modules/genko-markdown/web/js/codex"

window.ENDEC.onCleartext(($el) => {
  processBlocks($el, $el.dataset.postId ? `${$el.id}-` : "")
})

// 4. Initialize heading perma-links
window.ENDEC.onCleartext(($el) => {
  $el.querySelectorAll("h1,h2,h3,h4,h5,h6").forEach(($h) => {
    if (!$h.id) return
    const $link = document.createElement("a")
    $link.classList.add("perma-link")
    $link.href = `#${$h.id}`
    $link.textContent = "#"
    $h.prepend($link)
  })
})

// 5. Scroll to element specified by hash tag, if any
window.ENDEC.onCleartext(() => {
  const blockId = location.hash.replace(/^#/, "")
  const $el = document.getElementById(blockId)
  if (!$el) return
  window.addEventListener("load", () => {
    setTimeout(() => $el.scrollIntoView({}), 0)
  })
})
